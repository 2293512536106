

import remarkGfm from 'remark-gfm'; // 支持表格、脚注等 Markdown 扩展
import React, {useState, useEffect, useRef} from 'react';
import './App.css';
import {List, Avatar, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import 'antd/dist/reset.css';
import ChatInput from "./components/ChatInput";
import {Typography, Button, Space} from 'antd';
import {Layout} from 'antd';
import ReactMarkdown from 'react-markdown';

const {Header, Content, Footer} = Layout;


function App() {
  const system_prompt = "clanker"
  const MAX_MESSAGES = 6;
  const [messages, setMessages] = useState([
    {role: 'system', content: system_prompt}
  ]);
  const [inputValue, setInputValue] = useState('');
  const [faqSelected, setFaqSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const faqs = [
    "what's the creator rewards of specific token?",
    "what's clanker?",
    "who's proxy?",
    "when moon?",
    "how do the fees work?",
    "how launching works extractly?",
    "comparisons to pump fun?",
    "who's clankerpt?",
  ];

  const [forceUpdate, setForceUpdate] = useState(0);
  const chatHistoryRef = useRef(null);
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]); // 依赖messages数组


  const triggerUpdate = () => {
    setForceUpdate((prev) => prev + 1);
  };

  useEffect(() => {
    if (faqSelected) {
      handleSubmit(faqSelected);
      setFaqSelected(null);
    }
  }, [faqSelected]);

  const handleSubmit = async (userInput) => {
    if (!userInput) return;

    const newMessages = [
      ...messages,
      {role: 'user', content: userInput},
      {role: 'assistant', content: ''}
    ];
    setMessages(newMessages);
    setInputValue('');
    setLoading(true);

    try {
      const baseUrl = '';
      const endpoint = '/chat';
      const limitedMessages = [newMessages[0], ...newMessages.slice(-MAX_MESSAGES + 1)];
      const response = await fetch(`${baseUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({messages: limitedMessages}),
      });

      if (!response.ok) {
        console.error('connect to server error');
        setMessages([
          ...newMessages.slice(0, -1),
          {role: 'assistant', content: 'Sorry,network busy.You can try later.'}
        ]);
        setLoading(false);
        return;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let doneReading = false;

      let assistantContent = '';

      while (!doneReading) {
        const {value, done} = await reader.read();
        doneReading = done;

        if (value) {
          const chunk = decoder.decode(value, {stream: true});
          const lines = chunk.split('\n\n');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const dataStr = line.replace(/^data: /, '');

              if (dataStr === '[DONE]') {
                setLoading(false);
                doneReading = true;
                break;
              }

              try {
                const data = JSON.parse(dataStr);
                let content = data.choices[0].delta.content;

                if (content) {
                  // content = content + "\n"
                  assistantContent += content;

                  // 更新最后一条助手消息的内容
                  setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    updatedMessages[updatedMessages.length - 1] = {
                      ...updatedMessages[updatedMessages.length - 1],
                      content: assistantContent
                    };
                    return updatedMessages;
                  });
                  triggerUpdate(); // 强制触发重新渲染
                }
              } catch (e) {
                console.error('parse json error:', e);
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('connect to server error:', error);
      setMessages([
        ...messages,
        {role: 'assistant', content: 'Sorry,network busy.You can try later.'}
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleFaqClick = (faq) => {
    setFaqSelected(faq);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(inputValue);
    }
  };


  return (
      <Layout style={{minHeight: '100vh'}}>
        {/* 页头 */}
        <div style={{padding: '0 5vw'}}>
          <Header style={{backgroundColor: "#F1F3F4", padding: '0 24px'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              {/* 左侧：Logo 和标题 */}
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Avatar
                     src={process.env.PUBLIC_URL + '/urllog.png'}  // 请替换为你的图标链接
                    size="large"
                    alt="Logo"
                />
                <Typography.Title level={4} style={{margin: '0 0 0 16px'}}>
                  Play with ClankerPT
                </Typography.Title>
              </div>

              {/* 右侧：社交媒体图标 */}
              <div className="share-bar">
                {/*<Space size="large">*/}
                {/*  <a*/}
                {/*      href={`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent('play with clankerPT')}`}*/}
                {/*      target="_blank"*/}
                {/*      rel="noopener noreferrer"*/}
                {/*  >*/}
                {/*    <Avatar shape="square" size={32} src="https://cdn-icons-png.flaticon.com/32/733/733579.png"*/}
                {/*            alt="Twitter"/>*/}
                {/*  </a>*/}
                {/*  <a*/}
                {/*      href={`https://t.me/share/url?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent('play with clankerPT')}`}*/}
                {/*      target="_blank"*/}
                {/*      rel="noopener noreferrer"*/}
                {/*  >*/}
                {/*    <Avatar shape="square" size={32} src="https://cdn-icons-png.flaticon.com/32/2111/2111646.png"*/}
                {/*            alt="Telegram"/>*/}
                {/*  </a>*/}
                {/*  <a href="https://discord.gg/your-invite-code" target="_blank" rel="noopener noreferrer">*/}
                {/*    <Avatar shape="square" size={32} src="https://cdn-icons-png.flaticon.com/32/2111/2111370.png"*/}
                {/*            alt="Discord"/>*/}
                {/*  </a>*/}
                {/*  <a*/}
                {/*      href={`mailto:?subject=${encodeURIComponent('与 GPT-4 聊天')}&body=快来体验这个聊天机器人：${encodeURIComponent(window.location.href)}`}*/}
                {/*      target="_blank"*/}
                {/*      rel="noopener noreferrer"*/}
                {/*  >*/}
                {/*    <Avatar shape="square" size={32} src="https://cdn-icons-png.flaticon.com/32/732/732200.png"*/}
                {/*            alt="Email"/>*/}
                {/*  </a>*/}
                {/*</Space>*/}

                <Space size="large">
                  <a
                      href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Play with ClankerPT https://clankeronbase.com \nClank! Clank! Clank!')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    <Avatar shape="square" size={32} src={process.env.PUBLIC_URL + '/farcast.png'}
                            alt="Farcaster"/>
                  </a>


                  <a
                      href={`https://t.me/share/url?url=${encodeURIComponent("https://clankeronbase.com")}&text=${encodeURIComponent('Play with ClankerPT!')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    <Avatar shape="square" size={32} src="https://cdn-icons-png.flaticon.com/32/2111/2111646.png"
                            alt="Telegram"/>
                  </a>
                  <a
                      href={`https://twitter.com/share?url=${encodeURIComponent("https://clankeronbase.com")}&text=${encodeURIComponent('Play with ClankerPT!')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    <Avatar shape="square" size={32} src="https://cdn-icons-png.flaticon.com/32/733/733579.png"
                            alt="Twitter"/>
                  </a>

                  {/*<a href="https://discord.gg/your-invite-code" target="_blank" rel="noopener noreferrer">*/}
                  {/*  <Avatar shape="square" size={32} src="https://cdn-icons-png.flaticon.com/32/2111/2111370.png"*/}
                  {/*          alt="Discord"/>*/}
                  {/*</a>*/}
                  {/*<a*/}
                  {/*    href={`mailto:?subject=${encodeURIComponent('与 GPT-4 聊天')}&body=快来体验这个聊天机器人：${encodeURIComponent(window.location.href)}`}*/}
                  {/*    target="_blank"*/}
                  {/*    rel="noopener noreferrer"*/}
                  {/*>*/}
                  {/*  <Avatar shape="square" size={32} src="https://cdn-icons-png.flaticon.com/32/732/732200.png"*/}
                  {/*          alt="Email"/>*/}
                  {/*</a>*/}
                </Space>


              </div>
            </div>
          </Header>
        </div>

        {/* FAQ 部分 */}
        <Content>
          <div style={{padding: '0 10vw'}}>
            <div className="faq-section" style={{marginBottom: '16px'}}>
              <Typography.Title level={3}>FAQ:</Typography.Title>
              <Space wrap>
                {faqs.map((faq, index) => (
                    <Button key={index} onClick={() => handleFaqClick(faq)}>
                    {faq}
                    </Button>
                ))}
              </Space>
            </div>

            {/* 聊天历史 */}
            <div className="chat-history" style={{flex: 1, overflowY: 'auto', marginBottom: '16px', minHeight: '57vh'}}
                 ref={chatHistoryRef}>
              <List
                  itemLayout="horizontal"
                  dataSource={messages.slice(1)}
                  locale={{emptyText: 'No history Chat'}}
                  renderItem={(msg, index) => (
                      <List.Item key={index}>
                        <List.Item.Meta
                            avatar={
                              <Avatar style={{backgroundColor: msg.role === 'user' ? '#87d068' : '#1890ff'}}>
                                {msg.role === 'user' ? 'U' : 'A'}
                              </Avatar>
                            }
                            title={msg.role === 'user' ? 'User' : 'ClankerPT'}
                            description={
                              <div style={{color: '#333333'}}>
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>{msg.content}</ReactMarkdown>
                              </div>
                            }
                        />
                      </List.Item>
                  )}
              />
              {loading && (
                  <div className="chat-message assistant" style={{textAlign: 'center', marginTop: '16px'}}>
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}
                        tip="typing..."
                    />
                  </div>
              )}
            </div>

            {/* 输入框 */}
            <ChatInput
                inputValue={inputValue}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                loading={loading}
            />
          </div>
        </Content>
        {/* 页脚 */}
        {/*<Footer style={{textAlign: 'center'}}>©2024-2025 Clanker PT</Footer>*/}


        <Footer style={{textAlign: 'center', background: '#f0f2f5', padding: '20px 50px'}}>
          ©2024-2025 CPT
          <div style={{marginTop: '10px'}}>
            <Space size="middle">
              <a
                  href="https://www.clanker.world/clanker"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: '#1890ff', textDecoration: 'none'}}
              >
                Clanker
              </a>
              <a
                  href="https://warpcast.com/clanker"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: '#1890ff', textDecoration: 'none'}}
              >
                Warpcast
              </a>
              <a
                  href="https://dune.com/clanker_protection_team"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: '#1890ff', textDecoration: 'none'}}
              >
                Dune
              </a>
              <a
                  href="https://t.me/buildonclanker"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: '#1890ff', textDecoration: 'none'}}
              >
                Builder
              </a>
              <a
                  href="https://t.me/tradeonclanker"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: '#1890ff', textDecoration: 'none'}}
              >
                Trading
              </a>
              <a
                  href="https://t.me/clankerprice"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: '#1890ff', textDecoration: 'none'}}
              >
                Price Chat
              </a>
            </Space>
          </div>
        </Footer>

      </Layout>
  );
}

export default App;