import React from 'react';
import { Input, Button, Space } from 'antd';
import { SendOutlined } from '@ant-design/icons';

const ChatInput = ({ inputValue, handleInputChange, handleSubmit, loading }) => {
  const onKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(inputValue);
    }
  };

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input.TextArea
        value={inputValue}
        onChange={handleInputChange}
        onPressEnter={onKeyPress}
        placeholder="say something to clankerPT"
        disabled={loading}
        autoSize={{ minRows: 1, maxRows: 6 }}
        style={{ resize: 'none' }}
      />
      <Button
        type="primary"
        onClick={() => handleSubmit(inputValue)}
        disabled={loading || !inputValue.trim()}
        loading={loading}
        icon={<SendOutlined />}
        style={{ marginLeft: '8px' }} // 添加 marginTop
      >
        Send
      </Button>
    </Space.Compact>
  );
};

export default ChatInput;